import React, { useEffect, useState } from "react";
import Timer from "../CountdownTimer";
import { Nagad } from "../../logo/nagad";
import { JsxElement } from "typescript";
import { Rocket } from "../../logo/rocket";
import { Bkash } from "../../logo/bkash";

function Header(props: { walletCd: string }) {
  let walletCd: string = props.walletCd;

  const [background, setBackground] = useState("");
  const [walletCdName, setWalletCdName] = useState("");
  const [walletCdLogo, setWalletCdLogo] = useState<any>();

  useEffect(() => {
    if (walletCd === "BKASH") {
      setWalletCdLogo(<Bkash />);
      setWalletCdName("ক্যাশ আউট");
      setBackground("#D83868");
    } else if (walletCd === "ROCKET") {
      setWalletCdLogo(<Rocket />);
      setWalletCdName("ক্যাশ আউট");
      setBackground("#8C3494");
    } else if (walletCd === "NAGAD") {
      setWalletCdLogo(<Nagad />);
      setWalletCdName("ক্যাশ আউট");
      setBackground("linear-gradient(to bottom, #FC8A4A, #EE1C25)");
    }
  }, [walletCd]);

  return (
    <div
      className="header d-flex justify-content-center align-items-center"
      style={{
        background: background,
      }}
    >
      <div className="header-title">{walletCdName}</div>
      <div className="header-icon me-3" style={{ right: 0 }}>
        {/* <img src={walletCdLogo} alt="" /> */}
        {walletCdLogo}
      </div>
    </div>
  );
}

export default Header;

//http://localhost:3000/cash-deposit?walletCd=BKASH&cashId=123456&amount=100.00&walletAcc=789789&srcWalletAcc=asdasdasd22&sourceWalletAccOption=123|123111
