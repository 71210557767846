import * as React from "react";
export function Bkash() {
  return (
    <svg
      // width="65"
      // height="59"
      viewBox="0 0 65 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100%", height: "100%" }}
    >
      <path
        d="M0.343262 0L29.2153 3.76495L22.3664 27.7306L0.343262 0Z"
        fill="white"
      />
      <path
        d="M30.2395 3.8822L51.6933 32.411L26.9323 44.278L23.8623 31.7188C23.8623 31.7188 23.0613 27.1557 24.3172 23.6252L30.2395 3.8822Z"
        fill="white"
      />
      <path
        d="M52.4944 32.1821L44.5068 20.9961L57.973 18.7159L52.4944 32.1821Z"
        fill="white"
      />
      <path
        d="M58.9972 18.7159L64.0209 24.0801H56.717L58.9972 18.7159Z"
        fill="white"
      />
      <path
        d="M31.2666 43.4796L51.6961 33.3235L51.0096 35.7181L31.2666 43.4796Z"
        fill="white"
      />
      <path
        d="M22.3665 30.2424L13.8096 59L26.818 48.7267L22.3665 30.2424Z"
        fill="white"
      />
      <path
        d="M11.5265 15.406L3.1956 4.79211L0 4.90654L11.5265 15.406Z"
        fill="white"
      />
      <path
        d="M23.6584 30.1392C23.7338 31.102 23.8426 31.7244 23.8426 31.7244L26.9127 44.2835L51.6737 32.4166L51.4476 32.1151L23.6584 30.1392Z"
        fill="white"
      />
    </svg>
  );
}
