import * as React from "react";
export function Rocket() {
  return (
    <svg
      // width="94"
      // height="59"
      viewBox="0 0 94 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100%", height: "100%" }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.6463 7.6538L55.7979 21.4991L56.7696 29.2733L59.3209 23.9301L67.2153 34.0118L78.2688 0L41.462 15.3045L51.4234 20.5274L69.6463 7.6538Z"
        fill="white"
      />
      <path
        d="M57.6211 31.948H43.0415C31.9356 33.6478 30.7726 49.8038 42.9212 52.0003H57.6211V37.4115C62.52 37.575 63.5319 43.6894 59.9286 45.9167C60.6567 46.7651 63.572 49.9272 63.572 49.9272C71.2227 43.3254 66.8513 32.4601 57.6211 31.948ZM52.2779 46.5245H44.1398C39.0989 46.833 37.4577 38.5036 43.6524 37.4115H52.2903L52.2779 46.5245Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M88.1098 39.2469H82.7636L80.456 44.5931H87.2583C88.625 44.5314 88.6867 46.5367 87.2583 46.657H75.4769V37.4113H93.5856V31.9479H90.9017C90.1119 20.6507 76.5998 20.3144 73.0459 26.4813C73.7462 27.0582 77.0564 30.0043 77.0564 30.0043C79.0894 26.8145 84.7379 26.9656 85.5585 31.9479H70.1399V52.0001H87.5113C91.457 51.9415 93.2802 49.0262 93.7059 46.5367C93.9404 42.5139 91.5094 39.8423 88.1098 39.2469Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.1189 31.948H29.0728C26.8269 34.6813 22.9399 45.7255 30.6523 54.0549C30.6523 54.0549 33.9933 51.2013 34.6628 50.6614C32.2935 48.3446 28.3447 38.9385 36.1189 31.948Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1859 54.246C18.1865 55.1199 17.928 55.9743 17.4429 56.7012C16.9579 57.4281 16.2681 57.9948 15.461 58.3296C14.6538 58.6644 13.7655 58.7524 12.9083 58.5823C12.0512 58.4121 11.2637 57.9917 10.6456 57.374C10.0275 56.7563 9.60641 55.9691 9.4357 55.1121C9.26499 54.255 9.3523 53.3666 9.68657 52.5592C10.0208 51.7518 10.5871 51.0617 11.3136 50.5762C12.0402 50.0906 12.8944 49.8314 13.7683 49.8314C14.9394 49.8314 16.0626 50.2964 16.8909 51.1242C17.7193 51.952 18.1851 53.0749 18.1859 54.246Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.78603 31.948C-2.31985 33.6478 -3.4798 49.8038 8.66572 52.0003H8.74901C9.18417 51.0325 9.88969 50.211 10.7807 49.6347C11.6717 49.0584 12.7102 48.7519 13.7713 48.7519C14.8325 48.7519 15.871 49.0584 16.762 49.6347C17.653 50.211 18.3585 51.0325 18.7937 52.0003H23.3687V31.948H8.78603ZM18.0193 46.5245H9.8812C4.84036 46.833 3.19916 38.5036 9.39377 37.4115H18.0317L18.0193 46.5245Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.012 27.2156C15.8013 26.9071 16.6836 23.236 13.7683 22.4185H9.02975V29.4028H10.9733V27.2156L13.1605 29.4028H16.1992L14.012 27.2156ZM13.7806 24.7476C13.7806 24.8695 13.7322 24.9864 13.646 25.0726C13.5598 25.1588 13.4429 25.2073 13.3209 25.2073H10.998V24.2355H13.3209C13.4429 24.2355 13.5598 24.2839 13.646 24.3701C13.7322 24.4563 13.7806 24.5732 13.7806 24.6952V24.7476Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5804 22.3013C18.8798 22.3013 18.1949 22.5091 17.6125 22.8983C17.03 23.2876 16.5761 23.8409 16.3081 24.4882C16.0402 25.1355 15.9702 25.8478 16.1071 26.5349C16.244 27.2219 16.5816 27.853 17.0772 28.3481C17.5728 28.8433 18.2041 29.1804 18.8913 29.3167C19.5785 29.453 20.2907 29.3824 20.9378 29.1139C21.5849 28.8454 22.1378 28.3909 22.5265 27.8081C22.9153 27.2253 23.1225 26.5403 23.1219 25.8397C23.1211 24.901 22.7476 24.001 22.0835 23.3375C21.4194 22.674 20.5191 22.3013 19.5804 22.3013ZM19.5804 27.3945C19.2753 27.3945 18.9771 27.3041 18.7234 27.1346C18.4697 26.9651 18.272 26.7242 18.1553 26.4423C18.0385 26.1605 18.008 25.8504 18.0675 25.5511C18.127 25.2519 18.2739 24.9771 18.4897 24.7614C18.7054 24.5456 18.9802 24.3987 19.2794 24.3392C19.5787 24.2797 19.8888 24.3102 20.1706 24.427C20.4525 24.5437 20.6934 24.7414 20.8629 24.9951C21.0324 25.2488 21.1228 25.547 21.1228 25.8521C21.1228 26.2612 20.9603 26.6535 20.6711 26.9428C20.3818 27.232 19.9895 27.3945 19.5804 27.3945Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.6604 24.3126H42.841V22.2611H40.1509C38.0346 22.2611 36.3009 23.8591 36.3009 25.8088C36.3009 27.7585 38.0346 29.375 40.1509 29.375H42.841V27.3081H39.6604C39.438 27.3077 39.2186 27.2563 39.0192 27.1579C38.8197 27.0595 38.6454 26.9167 38.5097 26.7405H42.8287V24.8895H38.5097C38.6448 24.7119 38.8187 24.5676 39.0182 24.4676C39.2176 24.3676 39.4373 24.3146 39.6604 24.3126Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.0151 27.3081C26.6294 27.3065 26.26 27.1526 25.9873 26.8798C25.7146 26.6071 25.5606 26.2377 25.559 25.852V25.7687C25.5598 25.3828 25.7135 25.0129 25.9864 24.74C26.2593 24.4671 26.6292 24.3134 27.0151 24.3126H30.0353V22.2611H27.3236C25.2073 22.2611 23.4767 23.8591 23.4767 25.8088C23.4767 27.7585 25.2104 29.375 27.3267 29.375H30.0353V27.3081H27.0151Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50.2295 22.3938H43.3747V24.2324H45.8828V29.2517H47.7183V24.2324H50.2295V22.3938Z"
        fill="white"
      />
      <path
        d="M33.9131 25.7965L37.2881 22.3938H34.5548L32.6575 24.3096V22.3938H30.822V29.2517H32.6575V27.2866L34.6072 29.2517H37.3374L33.9131 25.7965Z"
        fill="white"
      />
      <path
        d="M61.5174 55.3875C61.1874 55.4676 60.9012 55.6721 60.7184 55.9582C60.5107 56.2228 60.3938 56.5474 60.3852 56.8837C60.3857 57.0719 60.4257 57.2579 60.5024 57.4297C60.6012 57.6549 60.7369 57.766 60.9035 57.766C61.0254 57.7522 61.141 57.7041 61.2367 57.6272L61.4279 58.1362C61.2781 58.2342 61.104 58.2887 60.9251 58.2935C60.6899 58.2882 60.4641 58.2009 60.2865 58.0467C60.1318 57.8946 60.0164 57.7073 59.9502 57.5007C59.8885 57.3096 59.8614 57.109 59.87 56.9084V56.7202C59.87 56.3089 60.0911 55.841 60.5333 55.3165C60.6806 55.1553 60.8383 55.0038 61.0053 54.863H61.6963V55.3659L61.5174 55.3875Z"
        fill="white"
      />
      <path
        d="M64.5869 55.372V58.2565H64.01V57.8832C63.975 57.6887 63.8709 57.5135 63.717 57.3896C63.5769 57.2422 63.4218 57.1098 63.2542 56.9947C63.0827 56.8739 62.8779 56.8092 62.6681 56.8096C62.5708 56.8045 62.4743 56.8281 62.3904 56.8775C62.3064 56.9269 62.2389 56.9998 62.1961 57.0873C62.1839 57.1111 62.1786 57.1377 62.1807 57.1644C62.1823 57.2054 62.1931 57.2456 62.2123 57.2819C62.2316 57.3182 62.2587 57.3497 62.2917 57.3742C62.3545 57.4311 62.4353 57.4639 62.52 57.4667C62.6722 57.4404 62.8205 57.3948 62.9611 57.331L63.1308 57.8554C62.935 57.9697 62.7154 58.0373 62.4891 58.0529C62.3011 58.0542 62.1171 57.9983 61.9616 57.8924C61.8047 57.7886 61.6823 57.6404 61.6099 57.4667C61.5754 57.3772 61.5586 57.2819 61.5606 57.186C61.5545 57.0363 61.5891 56.8878 61.6608 56.7563C61.7326 56.6248 61.8387 56.5153 61.9678 56.4394C62.1452 56.3184 62.3546 56.2529 62.5694 56.2512H62.6249C62.607 56.1245 62.551 56.0062 62.4645 55.9119C62.4302 55.8597 62.3847 55.8157 62.3313 55.7832C62.278 55.7506 62.2181 55.7303 62.156 55.7237H61.5174V55.372H61.2582V54.8661H64.9602V55.372H64.5869ZM64.01 55.372H62.6527C62.8268 55.4708 62.9732 55.612 63.0784 55.7824C63.1835 55.9528 63.244 56.1469 63.2542 56.3469C63.5458 56.4779 63.8042 56.673 64.01 56.9176V55.372Z"
        fill="white"
      />
      <path
        d="M65.7099 55.372V58.2565H65.133V55.372H64.7072V54.8661H66.0955V55.372H65.7099Z"
        fill="white"
      />
      <path
        d="M68.4925 55.372H65.895V54.8661H69.492V55.372H69.0941V58.2565H68.5974C68.548 58.1084 68.2889 57.8801 67.8508 57.5685C67.6132 57.4131 67.3573 57.2877 67.0888 57.1952C66.8267 57.0865 66.5484 57.0219 66.2652 57.004L66.1109 56.4302C66.4648 56.2102 66.8387 56.0242 67.2277 55.8749C67.4683 55.7607 67.8971 55.5972 68.4925 55.372ZM68.5264 55.989C68.4185 56.026 68.3043 56.0661 68.1809 56.1155L67.1937 56.5474L67.0827 56.5937C67.5523 56.7608 67.9934 56.9991 68.3907 57.3001L68.5264 57.4019V55.989Z"
        fill="white"
      />
      <path
        d="M70.217 55.372V58.2565H69.6401V55.372H69.2144V54.8661H70.5995V55.372H70.217Z"
        fill="white"
      />
      <path
        d="M70.399 55.3721V54.8662H72.1019C72.1041 54.8519 72.1041 54.8373 72.1019 54.823C72.1102 54.7058 72.0775 54.5894 72.0094 54.4937C71.9413 54.398 71.842 54.329 71.7286 54.2985C71.6917 54.2949 71.6545 54.2949 71.6176 54.2985C71.5413 54.2999 71.4656 54.3113 71.3923 54.3325L71.1702 54.388C71.0795 54.4014 70.9925 54.4329 70.9142 54.4805C70.8168 54.5482 70.7135 54.607 70.6057 54.6564L70.4206 54.2276C70.6899 54.0553 70.983 53.9233 71.2905 53.8358C71.4298 53.7945 71.5741 53.7727 71.7194 53.771C71.8914 53.78 72.0582 53.8331 72.2037 53.9252C72.3609 54.0266 72.4881 54.1682 72.5722 54.3352C72.6562 54.5023 72.6941 54.6888 72.6819 54.8754H73.015V55.3813L70.399 55.3721ZM72.4042 58.7409C72.1676 58.5285 71.9182 58.3307 71.6577 58.1486C71.4585 58.0282 71.2464 57.9309 71.0252 57.8586C70.8931 57.8025 70.7557 57.7602 70.6149 57.7321V57.0565H70.8371H71.0623C71.2787 57.0638 71.4945 57.0292 71.6978 56.9547C72.0063 56.8251 72.1636 56.6709 72.1636 56.495C72.1636 56.4302 72.1476 56.3664 72.1169 56.3094C72.0862 56.2523 72.0418 56.2037 71.9877 56.168C71.8726 56.1002 71.7553 56.0354 71.6361 55.9737C71.6065 55.9583 71.5738 55.9498 71.5404 55.949C71.4617 55.9517 71.3842 55.9695 71.3121 56.0014C71.2103 56.0508 71.1641 56.1032 71.1702 56.1618C71.1918 56.313 71.3492 56.3747 71.6422 56.3531L71.3337 56.8313C71.1955 56.8342 71.0601 56.7919 70.9481 56.711C70.8433 56.6523 70.7555 56.5675 70.6934 56.4647C70.6312 56.362 70.5967 56.2449 70.5933 56.1248C70.5944 56.0095 70.6291 55.8969 70.6932 55.801C70.7573 55.7051 70.8481 55.63 70.9543 55.585C71.1621 55.4746 71.3947 55.4194 71.6299 55.4245C71.7272 55.4214 71.824 55.4393 71.9137 55.477L72.1543 55.5973C72.3321 55.6721 72.4833 55.7985 72.5884 55.9602C72.6935 56.1219 72.7475 56.3115 72.7436 56.5043C72.7463 56.6427 72.7148 56.7796 72.6519 56.9029C72.5889 57.0261 72.4964 57.1319 72.3826 57.2107C72.1454 57.3678 71.8751 57.4681 71.5929 57.5038C71.6947 57.5532 71.8397 57.6334 72.034 57.7475C72.3113 57.9525 72.5751 58.1751 72.8238 58.4139L72.4042 58.7409Z"
        fill="white"
      />
      <path
        d="M76.4548 55.3721V58.2565H75.8779V57.112C75.8718 56.9978 75.8272 56.8891 75.7514 56.8035C75.7201 56.7579 75.6789 56.72 75.6308 56.6928C75.5826 56.6655 75.529 56.6496 75.4738 56.6461C75.421 56.6517 75.3702 56.6697 75.3257 56.6987C75.2812 56.7276 75.2442 56.7667 75.2177 56.8127C75.1368 56.9225 75.0945 57.0559 75.0974 57.1922H74.4804C74.484 57.0448 74.4408 56.9001 74.357 56.7788C74.3278 56.7287 74.2866 56.6866 74.237 56.6564C74.1874 56.6263 74.1311 56.6089 74.0732 56.606C73.9785 56.6075 73.887 56.6412 73.8141 56.7017C73.7777 56.7277 73.7469 56.7608 73.7236 56.7989C73.7003 56.8371 73.6849 56.8796 73.6783 56.9238L73.6598 57.075V57.1397C73.6598 57.4359 73.8418 57.584 74.2058 57.584C74.3095 57.5798 74.4126 57.5674 74.5143 57.547L74.3138 58.13C74.1935 58.1454 74.1226 58.1516 74.0979 58.1516C73.8185 58.1448 73.5515 58.0349 73.3482 57.8431C73.2467 57.7475 73.1713 57.6275 73.1292 57.4945C73.0783 57.3636 73.0511 57.2247 73.049 57.0842C73.042 56.9451 73.0641 56.8061 73.1139 56.6761C73.1638 56.5461 73.2402 56.4279 73.3384 56.3292C73.4366 56.2304 73.5543 56.1533 73.684 56.1027C73.8137 56.0521 73.9526 56.0291 74.0917 56.0353C74.2388 56.0375 74.3829 56.0769 74.5108 56.1497C74.6386 56.2225 74.7459 56.3264 74.8228 56.4518C74.8965 56.333 74.9972 56.2333 75.1168 56.1609C75.2364 56.0886 75.3714 56.0455 75.5108 56.0353C75.5851 56.0387 75.658 56.0568 75.7253 56.0886C75.7925 56.1203 75.8528 56.1652 75.9026 56.2204V55.369H72.7621V54.863H76.7725V55.369L76.4548 55.3721Z"
        fill="white"
      />
      <path
        d="M80.783 55.372H78.1855V54.8661H81.7825V55.372H81.3815V58.2565H80.8725C80.8231 58.1084 80.564 57.8801 80.1228 57.5685C79.8864 57.4129 79.6315 57.2875 79.3639 57.1952C79.1018 57.0865 78.8235 57.0219 78.5402 57.004L78.386 56.4302C78.7394 56.2095 79.1134 56.0235 79.5027 55.8749C79.7588 55.7607 80.1876 55.5972 80.783 55.372ZM80.8169 55.989C80.709 56.026 80.5948 56.0661 80.4714 56.1155L79.4842 56.5474L79.3732 56.5937C79.8428 56.7608 80.2839 56.9991 80.6812 57.3001L80.8169 57.4019V55.989Z"
        fill="white"
      />
      <path
        d="M82.7481 55.3721C82.5984 55.5462 82.5039 55.761 82.4767 55.9891C82.5431 56.3048 82.6359 56.6144 82.7543 56.9146C82.8411 57.1267 82.8912 57.352 82.9024 57.5809C82.9033 57.7451 82.8476 57.9047 82.7448 58.0327C82.642 58.1608 82.4983 58.2496 82.3378 58.2843C82.1375 58.346 81.9302 58.3823 81.7208 58.3923L81.801 57.8956C82.1404 57.8215 82.3101 57.7012 82.3101 57.5346C82.2842 57.283 82.212 57.0382 82.0972 56.8128C81.9831 56.5892 81.9109 56.3466 81.8843 56.097C81.9054 55.8406 81.9902 55.5935 82.1311 55.3783H81.5327V54.8723H83.1399V55.3783L82.7481 55.3721Z"
        fill="white"
      />
      <path
        d="M83.9852 55.372V58.2565H83.4052V55.372H82.9826V54.8661H84.3678V55.372H83.9852Z"
        fill="white"
      />
      <path
        d="M85.3148 56.7387C85.1945 56.7436 85.0745 56.7233 84.9624 56.6792C84.8503 56.6352 84.7487 56.5682 84.6639 56.4826C84.5817 56.3948 84.5178 56.2914 84.476 56.1786C84.4342 56.0658 84.4152 55.9458 84.4202 55.8255C84.4177 55.7111 84.4386 55.5974 84.4816 55.4913C84.5245 55.3852 84.5887 55.289 84.6701 55.2086C84.751 55.1277 84.8472 55.064 84.9532 55.0211C85.0592 54.9781 85.1727 54.9569 85.2871 54.9587C85.4479 54.9552 85.6057 55.0026 85.738 55.094C85.8704 55.1853 85.9706 55.3161 86.0244 55.4677C86.0861 55.6012 86.1187 55.7463 86.12 55.8934C86.133 56.1185 86.0577 56.3399 85.9102 56.5104C85.8323 56.5878 85.7391 56.648 85.6366 56.6873C85.5341 56.7266 85.4245 56.7441 85.3148 56.7387ZM85.9472 58.5897C85.4884 58.1721 84.9893 57.8011 84.4572 57.4822V56.6832C85.192 57.1626 85.8565 57.7419 86.4316 58.4046V59L85.9472 58.5897ZM85.4351 55.5572C85.4108 55.5384 85.3831 55.5246 85.3534 55.5167C85.3238 55.5087 85.2928 55.5067 85.2624 55.5109C85.0433 55.5448 84.9354 55.6528 84.9354 55.8348C84.9379 55.9298 84.9765 56.0203 85.0433 56.0878C85.0733 56.1236 85.1105 56.1525 85.1525 56.1728C85.1946 56.193 85.2404 56.204 85.2871 56.205H85.3673C85.438 56.1906 85.5014 56.1518 85.5465 56.0954C85.5916 56.0391 85.6155 55.9687 85.6141 55.8965C85.6148 55.8304 85.5987 55.7651 85.5675 55.7069C85.5362 55.6486 85.4906 55.5993 85.4351 55.5633V55.5572Z"
        fill="white"
      />
      <path
        d="M90.0657 54.5268C89.5799 54.1785 89.0103 53.9656 88.4152 53.9099C88.2047 53.893 87.9932 53.9277 87.7992 54.0111C87.6051 54.0944 87.4343 54.2238 87.3015 54.388C87.2099 54.536 87.1638 54.7076 87.1689 54.8816H87.573V55.3876H87.1689V58.272H86.5797V55.3876H86.1971V54.8816H86.5797C86.5781 54.6968 86.6133 54.5136 86.6832 54.3425C86.7531 54.1715 86.8563 54.016 86.9869 53.8852C87.2728 53.6151 87.641 53.4489 88.0327 53.4132L88.2918 53.3823H88.4152C88.7769 53.3851 89.134 53.4628 89.4641 53.6106C89.7813 53.7697 90.0901 53.9447 90.3896 54.1351L90.0657 54.5268Z"
        fill="white"
      />
      <path
        d="M89.5752 55.3721V55.3906C89.8403 55.4078 90.0979 55.486 90.3279 55.6189C90.4877 55.6892 90.6243 55.8033 90.722 55.948C90.8197 56.0926 90.8746 56.262 90.8801 56.4364C90.884 56.626 90.8181 56.8104 90.695 56.9547C90.5714 57.1023 90.402 57.2044 90.2138 57.2447C90.0478 57.2893 89.874 57.2967 89.7048 57.2663V56.7203C89.7664 56.7246 89.8282 56.7246 89.8899 56.7203C90.1798 56.7203 90.3218 56.6154 90.3218 56.4118C90.3218 56.2082 90.0719 56.0076 89.5752 55.9428V58.2473H89.0631C88.9986 58.1127 88.9167 57.9872 88.8194 57.874C88.7926 57.8432 88.7186 57.7475 88.5973 57.5871C88.5375 57.5145 88.4703 57.4483 88.3967 57.3897C88.3202 57.3335 88.2517 57.2671 88.1931 57.1923C88.1548 57.156 88.1082 57.1295 88.0574 57.1151C87.8602 57.0381 87.6518 56.9933 87.4404 56.9825L87.2892 56.4087C87.4157 56.3161 87.7026 56.1464 88.1468 55.8935L88.5541 55.6559L88.989 55.403V55.3629H87.1658V54.8569H91.1485V55.3629L89.5752 55.3721ZM88.9983 56.0385C88.9027 56.1094 88.7608 56.2051 88.5664 56.3254C88.5026 56.3555 88.445 56.3973 88.3967 56.4488C88.335 56.5105 88.2733 56.566 88.2178 56.6184C88.2795 56.674 88.3751 56.7511 88.5109 56.8529C88.6466 56.9547 88.8009 57.1614 88.9983 57.3866V56.0385Z"
        fill="white"
      />
      <path
        d="M92.0093 56.7387C91.8889 56.7439 91.7687 56.7239 91.6566 56.6798C91.5445 56.6357 91.4429 56.5685 91.3583 56.4826C91.2761 56.3947 91.2123 56.2914 91.1704 56.1786C91.1286 56.0658 91.1096 55.9457 91.1146 55.8255C91.1122 55.7111 91.1331 55.5974 91.176 55.4913C91.219 55.3852 91.2831 55.289 91.3645 55.2085C91.4453 55.1276 91.5416 55.0637 91.6476 55.0208C91.7536 54.9779 91.8671 54.9567 91.9815 54.9587C92.1419 54.9554 92.2992 55.0029 92.431 55.0943C92.5629 55.1857 92.6625 55.3163 92.7157 55.4677C92.7798 55.6005 92.8135 55.7459 92.8144 55.8934C92.8258 56.1183 92.7508 56.339 92.6047 56.5104C92.5267 56.5877 92.4335 56.648 92.331 56.6873C92.2285 56.7266 92.1189 56.7441 92.0093 56.7387ZM92.6263 58.5897C92.1676 58.1732 91.6696 57.8023 91.1393 57.4822V56.6831C92.1419 57.405 92.8021 57.9788 93.1106 58.4046V59L92.6263 58.5897ZM92.1172 55.5571C92.0929 55.5383 92.0652 55.5246 92.0355 55.5166C92.0058 55.5087 91.9749 55.5067 91.9445 55.5109C91.7254 55.5448 91.6175 55.6528 91.6175 55.8348C91.6199 55.9298 91.6586 56.0202 91.7254 56.0878C91.7554 56.1235 91.7926 56.1525 91.8346 56.1727C91.8766 56.193 91.9225 56.204 91.9692 56.205H92.0494C92.1201 56.1906 92.1835 56.1518 92.2286 56.0954C92.2737 56.0391 92.2976 55.9687 92.2962 55.8965C92.2982 55.8315 92.2841 55.767 92.255 55.7088C92.2259 55.6507 92.1828 55.6007 92.1296 55.5633L92.1172 55.5571Z"
        fill="white"
      />
      <path
        d="M28.2275 58.5033L29.3042 58.8673L29.733 58.5033L28.6594 58.127L28.2275 58.5033Z"
        fill="white"
      />
      <path
        d="M28.5854 55.5202V54.8693H20.1449V55.5202H21.564V56.9732H22.798C22.798 56.9732 23.1435 56.8621 23.2268 56.6863V57.2601C23.2319 57.38 23.1899 57.4972 23.1097 57.5865C23.0296 57.6759 22.9177 57.7303 22.798 57.7383H21.0056C20.8976 57.7383 20.7897 57.692 20.7897 57.2601V55.9058H20.1727V57.6426C20.1727 57.7876 20.2621 58.2936 20.7156 58.2936H23.1435C23.3217 58.2701 23.4861 58.1852 23.6084 58.0533C23.7306 57.9215 23.803 57.7512 23.8129 57.5717V55.9058H23.2391C23.1805 56.1619 23.2144 56.3346 22.9522 56.3994H22.2365V55.5202H24.3158V58.2967H25.0315V55.5202H25.5312V58.2967H27.8758C28.0692 58.291 28.2526 58.2096 28.3865 58.0699C28.5204 57.9302 28.5941 57.7435 28.5915 57.5501V55.9058H28.0177C28.0177 56.0662 27.9961 56.3038 27.7339 56.3038H26.1575V55.5202H28.5854ZM27.6599 56.8776C27.7679 56.9084 27.9128 56.7357 28.0177 56.5907V57.2601C28.0177 57.5162 27.8172 57.7383 27.6599 57.7383H26.1575V56.8776H27.6599Z"
        fill="white"
      />
      <path
        d="M30.8096 56.3038H29.3751V57.0689H30.8096V56.3038Z"
        fill="white"
      />
      <path
        d="M31.5994 54.8692V55.5201H33.9594V55.9057H31.5994V57.4482C31.6487 57.9511 31.9079 58.2904 32.3984 58.2904H34.5332V55.5139H35.036V58.2904H35.6808V54.8661L31.5994 54.8692ZM33.9594 57.8338H32.5279C32.312 57.803 32.1701 57.5469 32.1701 57.26V56.495H33.9594V57.8338Z"
        fill="white"
      />
      <path
        d="M38.775 55.5202H41.6995V55.9058H40.8327C40.8327 55.9819 40.8024 56.0549 40.7486 56.1087C40.6948 56.1625 40.6219 56.1927 40.5458 56.1927C40.4697 56.1927 40.3967 56.1625 40.3429 56.1087C40.2891 56.0549 40.2589 55.9819 40.2589 55.9058H38.7688V57.547C38.7688 57.7074 38.7935 58.2288 39.2316 58.2936H40.4039V57.7383H39.6141C39.5281 57.7205 39.4517 57.6716 39.3993 57.6011C39.347 57.5305 39.3224 57.4432 39.3303 57.3557V56.495H40.117V56.9732H40.8327V56.5907C40.7864 56.4148 41.5854 56.4303 41.6224 56.5907V58.2936H42.2672V55.5171H42.696V58.2936H43.3408V54.8693H38.775V55.5202Z"
        fill="white"
      />
      <path
        d="M37.7477 55.0605H36.2361V55.9583C36.2537 56.141 36.3385 56.3107 36.4742 56.4344C36.6099 56.5581 36.7867 56.627 36.9703 56.6277H38.1889V55.5202C38.1745 55.4063 38.1239 55.3001 38.0444 55.2173C37.9649 55.1345 37.8609 55.0795 37.7477 55.0605ZM37.285 56.02C37.0104 56.02 36.7883 55.9182 36.7883 55.7948C36.7883 55.6714 37.0104 55.5696 37.285 55.5696C37.5595 55.5696 37.7816 55.6714 37.7816 55.7948C37.7816 55.9182 37.5688 56.02 37.2942 56.02H37.285Z"
        fill="white"
      />
      <path
        d="M36.3379 56.967V57.5316L38.1765 58.2936V57.6951L36.3379 56.967Z"
        fill="white"
      />
      <path
        d="M53.1972 55.0605H51.6733V55.9583C51.6915 56.1408 51.7766 56.3101 51.9121 56.4336C52.0477 56.5572 52.2241 56.6263 52.4075 56.6277H53.6415V55.5202C53.6275 55.4057 53.5765 55.2989 53.4964 55.216C53.4162 55.133 53.3112 55.0785 53.1972 55.0605ZM52.7345 56.02C52.4569 56.02 52.2347 55.9182 52.2347 55.7948C52.2347 55.6714 52.4569 55.5696 52.7345 55.5696C53.0121 55.5696 53.2312 55.6714 53.2312 55.7948C53.2312 55.9182 53.0091 56.02 52.7345 56.02Z"
        fill="white"
      />
      <path
        d="M51.7751 56.967V57.5316L53.6168 58.2936V57.6951L51.7751 56.967Z"
        fill="white"
      />
      <path
        d="M57.1059 55.9058V55.5202H58.2936V54.8693H54.1721V55.5202H56.5321V55.9058H54.1721V57.4483C54.2245 57.9511 54.4806 58.2905 54.9711 58.2905H57.1059V56.5876H57.6797C57.7753 56.5876 57.8 56.5413 57.8216 56.6832V56.9701C57.7876 57.1151 57.7753 57.2108 57.6797 57.257H57.4668V57.8308H57.8956C57.9959 57.8139 58.0893 57.769 58.1651 57.7014C58.241 57.6337 58.2962 57.546 58.3244 57.4483V55.9058H57.1059ZM56.5321 57.8339H55.1006C54.8847 57.8031 54.7428 57.547 54.7428 57.2601V56.495H56.5228V56.5907L56.5321 57.8339Z"
        fill="white"
      />
      <path
        d="M45.2781 54.8692V55.5201H47.6381V55.9057H45.2781V57.4482C45.3275 57.9511 45.5866 58.2904 46.0771 58.2904H48.2119V55.5139H48.9585L48.687 56.0569V56.4394H49.1868C49.4551 56.4703 49.5477 56.6461 49.5477 57.1089C49.5477 57.4174 49.4737 57.658 49.2392 57.6827H48.7703V58.2565H49.2731C49.415 58.2565 50.0721 58.3213 50.1308 57.6827C50.2017 56.3931 50.2634 56.0569 49.3441 55.9612L49.4767 55.5139H50.4886V58.2904H51.1334V54.8661L45.2781 54.8692ZM47.6381 57.8338H46.2036C45.9907 57.803 45.8457 57.5469 45.8457 57.26V56.495H47.6381V57.8338Z"
        fill="white"
      />
    </svg>
  );
}
