export function Confirmation_Page(props: any) {
  let responsePage: string = props.responsePage;
  console.log(responsePage);
  let successFail: boolean = props.successFail;
  let amount: string = props.amount;
  let walletAcc: string = props.walletAcc;
  let srcWalletAcc: string = props.srcWalletAcc;
  let selectedSrcWalletAcc: string = props.selectedSrcWalletAcc;
  // let txnId: string = props.txnId;
  let responseUrl: string = props.responseUrl;
  let countdown: number = props.countdown;
  let multi: boolean = props.multi;
  let errorMessage: string = props.errorMessage;

  return (
    <div
      className="cash-deposit-container"
      style={{ display: `${responsePage}` }}
    >
      {successFail ? (
        <div className="response-instruction-wrapper">
          <div className="response-instruction">
            {/* SUCCESFULLY SUBMITTED */}
            সফলভাবে জমা দেওয়া হয়েছে
          </div>{" "}
          <br />
          <div className="response-subIntruction">
            {/* Your transaction has been submitted successfully and will be
              approved within 5 minutes. */}
            আপনার লেনদেন সফলভাবে জমা দেওয়া হয়েছে এবং 5 মিনিটের মধ্যে অনুমোদিত
            হবে।
          </div>
        </div>
      ) : (
        <div className="response-instruction-wrapper">
          <div className="response-instruction">
            ট্রান্সেকশন ব্যর্থ হয়েছে, অনুগ্রহ করে আবার চেষ্টা করুন
          </div>
          {/* <div className="response-subIntruction">
            Transaction failed, please try again.
            ট্রান্সেকশন ব্যর্থ হয়েছে, অনুগ্রহ করে আবার চেষ্টা করুন
          </div> */}
          <div className="response-subIntruction">
            {/* Transaction ID Already Exists */}
            {errorMessage == "Transaction ID exist"
              ? "ট্রান্সেকশন আইডি ইতিমধ্যেই বিদ্যমান"
              : ""}
          </div>
        </div>
      )}
      <div className="horizontalLn"></div>
      <div className="depositItem">
        <div className="title">পরিমাণ:</div>
        <div className="prefilled-input">{amount} </div>
      </div>
      <div className="depositItem">
        <div className="title">নগদ এজেন্ট নাম্বার:</div>
        <div className="prefilled-input">{walletAcc}</div>
      </div>
      <div className="depositItem">
        <div className="title">ক্যাশ আউট ফ্রম:</div>
        <div className="prefilled-input">
          {multi ? selectedSrcWalletAcc : srcWalletAcc}{" "}
        </div>
      </div>
      {/* <div className="depositItem">
        <div className="title">ট্রানসাকশান আইডি:</div>
        <div className="prefilled-input">{txnId} </div>
      </div> */}
      <div className="text-center">
        {" "}
        <a
          href={errorMessage == "Transaction ID exist" ? "" : responseUrl}
          className="btn btn-info"
        >
          Redirecting After {countdown} seconds...
        </a>
      </div>
    </div>
  );
}
