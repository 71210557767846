import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import clsx from "clsx";
import Header from "./header";
import { CopyToClipboard } from "../../functions/CopyToClipboard";
import Timer from "../CountdownTimer";
import { Confirmation_Page } from "./confirmation_page";
import { Instruction } from "./Instruction/Instruction";
import Footer from "./footer";
import { Justpay_Logo } from "../../logo/justpay_logo";

function Body() {
  const location = useLocation();
  const API = process.env.REACT_APP_SISPAY_API;

  //const [countdown, setCountdown] = useState(0);
  const [loading, setLoading] = useState(Boolean);
  const [cashId, setCashId] = useState("");
  const [walletCd, setWalletCd] = useState("");
  const [amount, setAmount] = useState("");
  const [agentNumber, setAgentNumber] = useState("");
  const [walletAcc, setWalletAcc] = useState("");
  const [srcWalletAcc, setSrcWalletAcc] = useState("");
  const [sourceWalletAccOption, setSourceWalletAccOption] = useState([]);
  const [multi, setMulti] = useState(Boolean);
  const [selectedSrcWalletAcc, setSelectedSrcWalletAcc] = useState("");
  const [responseUrl, setResponseUrl] = useState("");
  const [countdown, setCountdown] = useState(5);
  const [errorMessage, setErrorMessage] = useState(false);

  const [txnId, setTxnId] = useState("");
  const [tutorialPic, setTutorialPic] = useState("");
  const [formPage, setFormpage] = useState("block");
  const [responsePage, setResponsePage] = useState("none");
  const [successFail, setSucccessFail] = useState(Boolean);
  const [background, setBackground] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [line3, setLine3] = useState("");
  const [line4, setLine4] = useState("");
  const [line5, setLine5] = useState("");
  const [txnIdValidation, setTxnIdValidation] = useState<any>();

  function convertStringToArray(str: any) {
    return str.split("|").map((numStr: any) => {
      return {
        sourceWalletAccOption: String(numStr),
      };
    });
  }

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [countdown]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cashId: any = urlParams.get("cashId");
    const walletCd: any = urlParams.get("walletCd");
    const amount: any = urlParams.get("amount");
    const walletAcc: any = urlParams.get("walletAcc");
    const srcWalletAcc: any = urlParams.get("srcWalletAcc");
    const sourceWalletAccOption: any = urlParams.get("sourceWalletAccOption");

    setCashId(cashId);
    setWalletCd(walletCd);
    setAmount(amount);
    setWalletAcc(walletAcc);

    if (sourceWalletAccOption !== null && srcWalletAcc == null) {
      setMulti(true);
      setSourceWalletAccOption(convertStringToArray(sourceWalletAccOption));
    } else {
      setMulti(false);
      setSrcWalletAcc(srcWalletAcc);
    }

    //Set Tutorial Pic
    if (walletCd == "NAGAD") {
      setTxnIdValidation(
        Yup.string()
          .matches(
            /^[a-zA-Z0-9]{8}$/,
            "অবৈধ লেনদেন আইডি লেনদেন আইডি অবশ্যই ৮ সংখ্যার আলফানিউমেরিক সহ। অনুগ্রহ করে সঠিকভাবে লিখুন।"
          )
          .required("এখানে ট্রানজেকশন আইডি বাধ্যতামূলক")
      );
      setAgentNumber("নগদ এজেন্ট নম্বর:");
      setTutorialPic("assets/tutorialss_nagad.png");
      setLine1("কিভাবে ডিপোজিট করবেন?");
      setLine2("আপনার নগদ ওয়ালেট নম্বর লিখুন");
      setLine3(
        "নগদ অ্যাপ দিয়ে পেমেন্ট করুনআপনার নগদ অ্যাপ খুলুন, ক্যাশ-আউট নির্বাচন করুন এবং প্রদত্ত নগদ এজেন্ট নম্বরে ডিপোজিট পরিমাণ পাঠান"
      );
      setLine4(
        "নগদ ট্রানসাকশান আইডি লিখুনআপনার নগদ ক্যাশ-আউট ট্রানসাকশান থেকে ট্রানসাকশান আইডি লিখুন"
      );
      setLine5(
        "আপনার ডিপোজিট নিশ্চিত করুনসম্পন্ন হয়েছে! আপনার ডিপোজিট পরিমাণআপনার ক্রেডিট ব্যালেন্সে যোগ করা হবে।"
      );
    } else if (walletCd == "ROCKET") {
      setTxnIdValidation(
        Yup.string()
          .matches(
            /^[0-9]{10}$/,
            "অবৈধ লেনদেন আইডি লেনদেন আইডি অবশ্যই ১০ সংখ্যার সাংখ্যিক সহ। অনুগ্রহ করে সঠিকভাবে লিখুন।"
          )
          .required("এখানে ট্রানজেকশন আইডি বাধ্যতামূলক")
      );
      setAgentNumber("রকেট এজেন্ট নম্বর:");
      setTutorialPic("assets/tutorialss_rocket.png");
      setLine1("কিভাবে ডিপোজিট করবেন?");
      setLine2("আপনার রকেট ওয়ালেট নম্বর লিখুন");
      setLine3(
        "রকেট অ্যাপ দিয়ে অর্থপ্রদান করুনআপনার রকেট অ্যাপ খুলুন, ক্যাশ-আউট নির্বাচন করুন এবং প্রদত্ত রকেট এজেন্ট নম্বরে ডিপোজিট পরিমাণ পাঠান"
      );
      setLine4(
        "রকেট ট্রানসাকশান আইডি লিখুনআপনার রকেট ক্যাশ-আউট ট্রানসাকশান থেকে ট্রানসাকশান আইডি লিখুন"
      );
      setLine5(
        "আপনার ডিপোজিট নিশ্চিত করুনসম্পন্ন হয়েছে! আপনার ডিপোজিট পরিমাণ আপনার ক্রেডিট ব্যালেন্সে যোগ করা হবে"
      );
    } else if (walletCd == "BKASH") {
      setTxnIdValidation(
        Yup.string()
          .matches(
            /^(?![A-Za-z]{10}$|[0-9]{10}$)[0-9A-Za-z]{10}$/,
            "অবৈধ লেনদেন আইডি লেনদেন আইডি অবশ্যই ১০ সংখ্যার আলফানিউমেরিক সহ। অনুগ্রহ করে সঠিকভাবে লিখুন।"
          )
          .required("এখানে ট্রানজেকশন আইডি বাধ্যতামূলক")
      );
      setAgentNumber("বিকাশ এজেন্ট নম্বর:");
      setTutorialPic("assets/tutorialss_bkash.png");
      setLine1("কিভাবে ডিপোজিট করবেন?");
      setLine2("আপনার বিকাশ ওয়ালেট নম্বর লিখুন");
      setLine3(
        "বিকাশ অ্যাপ দিয়ে পেমেন্ট করুনআপনার বিকাশ অ্যাপ খুলুন, ক্যাশ-আউট নির্বাচন করুনএবং প্রদত্ত বিকাশ এজেন্ট নম্বরে ডিপোজিট পরিমাণ পাঠান"
      );
      setLine4(
        "বিকাশ ট্রানসাকশান আইডি লিখুন আপনার বিকাশ ক্যাশ-আউট ট্রানসাকশান থেকে ট্রানসাকশান আইডি লিখুন"
      );
      setLine5(
        "আপনার ডিপোজিট নিশ্চিত করুন।সম্পন্ন হয়েছে আপনার ডিপোজিট পরিমাণ আপনার ক্রেডিট ব্যালেন্সে যোগ করা হবে"
      );
    }

    urlParams.delete("cashId");
    urlParams.delete("walletCd");
    urlParams.delete("amount");
    urlParams.delete("walletAcc");
    urlParams.delete("srcWalletAcc");
    urlParams.delete("sourceWalletAccOption");
    const newUrl = window.location.pathname;
    window.history.replaceState({ path: newUrl }, "", newUrl);
  }, []);

  const initialValues = {
    multi: multi,
    amount: amount,
    walletAcc: walletAcc,
    srcWalletAcc: srcWalletAcc,
    sourceWalletAccOption: "",
    cashId: cashId,
    txnId: "",
  };

  //Single Src Wallet Validation
  const singleSrcWalletSchema = Yup.object().shape({
    multi: Yup.boolean().required("Multi is required"),
    cashId: Yup.string().required("Cashout amount is required"),
    srcWalletAcc: Yup.string().required(
      "রেজিস্টার্ড নাম্বার থেকে ক্যাশ আউট বাধ্যতামূলক"
    ),
    txnId: txnIdValidation,
  });

  //Multi Src Walelt Validation
  const multiSrcWalletSchema = Yup.object().shape({
    multi: Yup.boolean().required("Multi is required"),
    cashId: Yup.string().required("Cashout amount is required"),
    sourceWalletAccOption: Yup.string().required(
      "রেজিস্টার্ড নাম্বার থেকে ক্যাশ আউট বাধ্যতামূলক"
    ),
    txnId: txnIdValidation,
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: multi ? multiSrcWalletSchema : singleSrcWalletSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setSrcWalletAcc(values.srcWalletAcc);

      const data: any = {};
      let apiLink = ``;

      if (multi == false) {
        data.cashId = values.cashId;
        data.sourceWalletAcc = values.srcWalletAcc;
        data.txnId = values.txnId.toUpperCase();
      } else if (multi == true) {
        data.cashId = values.cashId;
        data.sourceWalletAcc = values.sourceWalletAccOption;
        data.txnId = values.txnId.toUpperCase();
        setSelectedSrcWalletAcc(String(values.sourceWalletAccOption));
      }

      if (walletCd == "BKASH") {
        //Phase 1
        apiLink = `updatetrxid`;
      } else {
        //Phase 2
        apiLink = `updatesrcwallet`;
      }

      try {
        axios
          .post(
            `${API}/cash/cashrequest/${apiLink}`,
            {
              request: data,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(function (response) {
            if (response.data.code == "200") {
              setSucccessFail(true);
              setFormpage("none");
              setResponsePage("block");
              setCashId(values.cashId);
              // setTxnId(values.txnId);
              //setTutorialPic("assets/tick.svg");
              setBackground("#3FCA0E");
              setResponseUrl(response.data.result.responseUrl);
              setCountdown(5);
              setTimeout(() => {
                window.location.href = `${response.data.result.responseUrl}`;
              }, 5000);
            } else if (response.data.code == "400") {
              setFormpage("none");
              setResponsePage("block");
              setCashId(values.cashId);
              // setTxnId(values.txnId);
              setSucccessFail(false);
              //setTutorialPic("assets/cross.svg");
              setBackground("#CA0E0E");
              setCountdown(5);
              setErrorMessage(response.data.message);
              setTimeout(() => {
                if (response.data.message == "Transaction ID exist") {
                  setFormpage("block");
                  setResponsePage("none");
                  setLoading(false);
                } else {
                  window.location.href = `${response.data.result.responseUrl}`;
                }
              }, 5000);
            }
          })
          .catch(function (error) {});
      } catch (error) {}
    },
  });

  return (
    <div>
      {" "}
      <Header walletCd={walletCd} />
      <div className="body">
        <div className="container d-flex flex-column align-items-center justify-content-center">
          <div
            className="cash-deposit-container"
            style={{ display: `${formPage}` }}
          >
            <Justpay_Logo />

            <div className="fs-6 text-center mb-3 fw-bold">
              সময়সীমা <Timer countdown={900} />
            </div>
            <form onSubmit={formik.handleSubmit} className="m-10">
              {/* begin::Form group Multi */}
              <div className="fv-row mb-5 d-none">
                <label className="form-label fw-bolder text-dark fs-6">
                  Multi
                </label>
                <select
                  {...formik.getFieldProps("multi")}
                  className={clsx("form-control bg-transparent")}
                  data-placeholder=""
                >
                  <option value="">ALL</option>
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>{" "}
              {/* end::Form group */}
              {/* begin::Form group Amount from Merchant Input */}
              <div className="fv-row mb-8">
                <label className="fw-bold">ডিপোজিট পরিমাণ:</label>

                <div className="prefilled-input" data-value="amount">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 384 512"
                    className="me-2"
                    fill="#6c6c6c"
                  >
                    <path d="M36 32.2C18.4 30.1 2.4 42.5 .2 60S10.5 93.6 28 95.8l7.9 1c16 2 28 15.6 28 31.8V160H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H64V384c0 53 43 96 96 96h32c106 0 192-86 192-192V256c0-53-43-96-96-96H272c-17.7 0-32 14.3-32 32s14.3 32 32 32h16c17.7 0 32 14.3 32 32v32c0 70.7-57.3 128-128 128H160c-17.7 0-32-14.3-32-32V224h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H128V128.5c0-48.4-36.1-89.3-84.1-95.3l-7.9-1z" />
                  </svg>
                  {amount}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-clipboard"
                    viewBox="0 0 16 16"
                    onClick={() => CopyToClipboard(amount, "amount")}
                  >
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                </div>
              </div>
              {/* end::Form group */}
              {/* begin::Form group Wallet Account Number from RR */}
              <div className="fv-row mb-8">
                <label className="fw-bold">{agentNumber}</label>
                <div className="prefilled-input" data-value="walletAcc">
                  {walletAcc}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-clipboard"
                    viewBox="0 0 16 16"
                    onClick={() => CopyToClipboard(walletAcc, "walletAcc")}
                  >
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                </div>{" "}
              </div>
              {/* end::Form group */}
              {/* begin::Form group Source Account Number from Merchant Input */}
              {!multi ? (
                <div className="fv-row fw-bold mb-8">
                  <label className="form-label">ক্যাশ আউট ফ্রম:</label>
                  <input
                    style={{
                      paddingTop: "0.8rem",
                      paddingBottom: "0.8rem",
                      paddingLeft: "0.5rem",
                      paddingRight: "0.5rem",
                    }}
                    placeholder="এখান থেকে ক্যাশ আউট করবেন"
                    type="text"
                    autoComplete="off"
                    disabled={srcWalletAcc == "" ? false : true}
                    {...formik.getFieldProps("srcWalletAcc")}
                    className={clsx(
                      "form-control bg-grey w-100",
                      {
                        "is-invalid":
                          formik.touched.srcWalletAcc &&
                          formik.errors.srcWalletAcc,
                      },
                      {
                        "is-valid":
                          formik.touched.srcWalletAcc &&
                          !formik.errors.srcWalletAcc,
                      }
                    )}
                  />
                  {formik.touched.srcWalletAcc &&
                    formik.errors.srcWalletAcc && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.srcWalletAcc}</span>
                        </div>
                      </div>
                    )}
                </div>
              ) : (
                <div className="fv-row mb-8">
                  <label className="form-label fw-bold">ক্যাশ আউট ফ্রম:</label>
                  <select
                    {...formik.getFieldProps("sourceWalletAccOption")}
                    className={clsx(
                      "form-control bg-white w-100",
                      {
                        "is-invalid":
                          formik.touched.sourceWalletAccOption &&
                          formik.errors.sourceWalletAccOption,
                      },
                      {
                        "is-valid":
                          formik.touched.sourceWalletAccOption &&
                          !formik.errors.sourceWalletAccOption,
                      }
                    )}
                    data-placeholder=""
                  >
                    <option value="">Please Select</option>
                    {sourceWalletAccOption.length > 0 &&
                      sourceWalletAccOption.map(
                        (sourceWalletAccOption: any) => (
                          <option
                            value={sourceWalletAccOption.sourceWalletAccOption}
                          >
                            {sourceWalletAccOption.sourceWalletAccOption}
                          </option>
                        )
                      )}
                  </select>
                  {formik.touched.sourceWalletAccOption &&
                    formik.errors.sourceWalletAccOption && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {formik.errors.sourceWalletAccOption}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              )}
              {/* end::Form group */}
              {/* begin::Form group Transaction ID */}
              <div className="fv-row mb-8">
                <label className="form-label fw-bold">ট্রানসাকশান আইডি:</label>
                <input
                  style={{
                    paddingTop: "0.8rem",
                    paddingBottom: "0.8rem",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                  }}
                  placeholder="এখানে আপনার ট্রানজেকশন আইডিটা লিখুন।"
                  type="text"
                  autoComplete="off"
                  {...formik.getFieldProps("txnId")}
                  className={clsx(
                    "form-control bg-grey w-100",
                    {
                      "is-invalid": formik.touched.txnId && formik.errors.txnId,
                    },
                    {
                      "is-valid": formik.touched.txnId && !formik.errors.txnId,
                    }
                  )}
                />
                {formik.touched.txnId && formik.errors.txnId && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.txnId}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}
              {/* begin::Form group */}
              <div className="text-center mt-4">
                <button
                  type="submit"
                  id="kt_sign_up_submit"
                  className="btn btn-lg btn-submit w-100 mb-3"
                  disabled={formik.isSubmitting || !formik.isValid || loading}
                >
                  {!loading && (
                    <span className="indicator-label">সাবমিট করুন</span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Form group */}
            </form>
            <Instruction
              tutorialPic={tutorialPic}
              line1={line1}
              line2={line2}
              line3={line3}
              line4={line4}
              line5={line5}
            />
          </div>
          <Confirmation_Page
            responsePage={responsePage}
            successFail={successFail}
            amount={amount}
            walletAcc={walletAcc}
            srcWalletAcc={srcWalletAcc}
            selectedSrcWalletAcc={selectedSrcWalletAcc}
            // txnId={txnId}
            responseUrl={responseUrl}
            countdown={countdown}
            multi={multi}
            errorMessage={errorMessage}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Body;
