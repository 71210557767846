import { useEffect, useState } from "react";
import Footer from "./Cash-Deposit/footer";
import Header from "./Cash-Deposit/header";
import { Justpay_Logo } from "../logo/justpay_logo";

function ErrorPage() {
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsg2, setErrorMsg2] = useState("");
  const [walletCd, setWalletCd] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const error: any = urlParams.get("info");
    const walletCd: any = urlParams.get("walletCd");

    if (error == "No Available Wallet") {
      setErrorMsg("No available wallet at the moment, please try again later.");
      setErrorMsg2(
        "দুঃখিত এই মুহূর্তে কোন ওয়ালেট ব্যবহারযোগ্য নেই , অনুগ্রহ করে কিছুক্ষণ পরে আবার চেষ্টা করুন ধন্যবাদ."
      );
    } else {
      setErrorMsg(String(error).replace("+", " "));
    }

    setWalletCd(walletCd);
  }, []);

  return (
    <div>
      <Header walletCd={walletCd} />
      <div className="body">
        <div className="container d-flex flex-column align-items-center justify-content-center">
          <div className="cash-deposit-container">
            <Justpay_Logo />
            <div className="d-flex flex-column align-items-center justify-content-center error-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                // width="16"
                // height="16"
                fill="red"
                className="bi bi-exclamation-triangle-fill"
                viewBox="0 0 16 16"
                style={{ width: "50%" }}
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
              <div className="text-center mt-3">{errorMsg2}</div>
              <div className="text-center mt-3 mb-5">{errorMsg}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ErrorPage;
