import React, { useState, useEffect } from "react";

const Timer = (props: any) => {
  let countdown: number = Number(props.countdown);

  const [seconds, setSeconds] = useState<number>(countdown);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [seconds]);

  const formatTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return <span className="fw-bold">{formatTime(seconds)}</span>;
};

export default Timer;
