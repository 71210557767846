import * as React from "react";
export function Justpay_Logo() {
  return (
    <div className="text-center mb-3 d-flex justify-content-center align-items-center">
      <img
        src="\assets\justpaylogo_2.png"
        alt="justpay-logo"
        className="justpay-logo"
      />
      {/* <img
        src="../assets/justpaylogo_2.png"
        alt="justpay-logo"
        className="justpay-logo"
      /> */}
    </div>
  );
}
