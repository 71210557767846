import React from "react";
import { Route, Routes } from "react-router-dom";
import Body from "./components/Cash-Deposit/body";
import Deposit from "./components/deposit";
import ErrorPage from "./components/error";

function App() {
  return (
    <Routes>
      <Route path="/cash-deposit" element={<Body />}></Route>
      <Route path="/cash-deposit/error" element={<ErrorPage />}></Route>
      {/* <Route path="/sispay-demo" element={<Deposit />}></Route> */}
    </Routes>
  );
}

export default App;
